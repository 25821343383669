export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        title: "智能仓储",
        text: ["采用RFID技术作为仓储管理系统中的关键技术，可显著提升仓库及物流管理技术水平，企业可获得良好的投资回报，在未来更可有效避免和减少企业信息化升级时的重复投资。", "（1）系统可实现仓库实时数据在管理中心的可视化管理，真正做到实物流与数据流实现同步。", "（2）实现托盘和装卸点的电子化，实现货物高效出入库，有效完成进行管理分配，实现货物定位，快速检索。"]
      }, {
        title: "智能分拣",
        text: ["基于RFID技术的“自动化分拣”解决方案，采用更为先进的自动识别技术，相比于传统的分拣系统，具有更低的出错率、不受货品放置方向的影响、可穿透绝大部分物质读取、存储更多的物料信息，更好的扩展性。采用RFID技术，将RFID读写装置安装在输送线关键节点上，可以自动实现对包装物信息的跟踪管理。自动记录在输物品的操作过程，操作时间，对提高输送速度、减少发送错误、货物丢失等有重要的作用。"]
      }, {
        title: "可视物流",
        text: ["物流各环节实现物流信息的可视化，包括物流资源信息、物流需求信息、物流过程、物流状态、物流控制和物流环境的可视化。物流可视化针对物流信息，它的前提是物流的数字化。"]
      }]
    };
  }
};