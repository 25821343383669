export default {
  data: function data() {
    return {
      list: [{
        title: "扫描速度快",
        c: "超高频RFID电子标签可实现多标签同时扫描，速度快，极大地提高了工作效率。",
        icon: require("@/assets/warehouse-logistics/a/icon1.png")
      }, {
        title: "货位准确的识别",
        c: "通过叉车上的无线终端及RFID读写器读取到的RFID货位标签，保证货物放在正确的位置。",
        icon: require("@/assets/warehouse-logistics/a/icon2.png")
      }, {
        title: "RFID标签可重复使用",
        c: "RFID标签使用寿命一般大于20年(10万次读写)，并且内容可更改，所以可重复使用，降低企业成本。",
        icon: require("@/assets/warehouse-logistics/a/icon3.png")
      }, {
        title: "远距离识别货物，免去人工查找",
        c: "RFID叉车管理可实现远距离读取货架、物品上的电子标签，可远距离的查找到要出、入库的物品的位置，避免误搬误入。",
        icon: require("@/assets/warehouse-logistics/a/icon4.png")
      }]
    };
  }
};