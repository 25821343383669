import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6bd99504"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "warehouse-logistics"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_warehouse_logistics_b = _resolveComponent("warehouse-logistics-b");

  var _component_warehouse_logistics_a = _resolveComponent("warehouse-logistics-a");

  var _component_warehouse_logistics_c = _resolveComponent("warehouse-logistics-c");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top), _createVNode(_component_warehouse_logistics_b), _createVNode(_component_warehouse_logistics_a), _createVNode(_component_warehouse_logistics_c), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '0 0 .4rem'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}